html {
  font-size: 16px;
}

body {
  --mdc-theme-text-primary-on-background: #686377;
  --mdc-theme-secondary: #58468c;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  color: #686377;
}

input, button {
  font: inherit;
  color: inherit;
}

label {
  font-weight: 500;
}

a {
  color: #58468c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.mdc-form-field {
  padding-left: 8px;
  font-family: inherit;
  font-size: 1rem;
}

div.mdc-form-field > label {
  padding-left: 0;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
